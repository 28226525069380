import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { FiRefreshCw } from "react-icons/fi";
import axios from "axios";
import SweetAlertHelper from "./SweetAlertHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import {
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useSpeechSynthesis } from "react-speech-kit";
import { faVolumeUp, faPause } from "@fortawesome/free-solid-svg-icons";
import { ApiHelper } from "../_helpers/api.helper";
import { environment } from "src/environments/environment";
import {
  faChevronRight,
  faChevronLeft,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { openaiEnv } from "./chatbot.env";
import {ConstantHelper} from '../_helpers/constant.helper';
import prompts from "./AIPrompt";
let demo = "";
let file;
let html_table;
let specialistName = "";
let sub_specialistName = "";
let subSpecialistNames = ([] = []);
let practice_name = "";
let physician_name = "";
let SecondOpinionjson;
let SecondOpinionjson1;
let SecondOpinionjsonObject;
let qcount;
let patientData;
let Documentjson;
let patientDoc;
let limit;
let pendingReferalOpinionList;
let PendingSecondOpinionjsonObject;
let PendingSecondOpinionjson;
let checkboxtable;
let audioBlob : Blob;
let sumres;
let patient_id;
let UploadVideojsonObject;
let UploadPrescriptionObject;
let UploadVideojson;
let fileNames;
let videosum;
let r1;
let azureUploadResponse;
let uploadVideoFileUrl;
let uploadFileUrl;
let uploadVideoFileName;
let popres = ""
let patientDetailspop ;
let patientdictdata;
let prevVideoSummary="";
let file_desc = ""


// enum TranscribeStatus {
//   Initial = 'initial',
//   Pending = 'pending',
//   Error = '400 bad request',
//   Success = 'success',
// }


interface Message {
  text: string;
  user: boolean;
}
declare global {
  interface Window {
    test?: (btnName) => void;
    demoTest?: (url) => void;
    printDocumentUrl?: (url) => void;
    testRespond?: (RecordId) => void;
    testUpdatedRespond?: (btntxt) => void;
    testAutoRespond?: (btntext) => void;
    generatePDF?: (UserId) => void;
  }
}

interface MediaRecorder {
  state: string;
  start(): void;
  stop(): void;
  pause(): void; 
  resume(): void; 
}
let thread_id = 0;

const AHSChatBot = (props) => { 

  function capitalizeFirstLetter(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }  
  const patientId = props.patientId;
  const isPopUp = props.isPopUp ? 'true' : 'false';  
  let empName = localStorage.getItem("loggedInUserName");
  let nameParts = empName.split(" ");
  let firstName = capitalizeFirstLetter(nameParts[0]);
  let lastName = capitalizeFirstLetter(nameParts[nameParts.length - 1]);
  let updatedLoggedInUserName = firstName + " " + lastName;  
  const [response, setResponse] = useState("");
  const [textBeforeButtons, SettextBeforeButtons] = useState("");
  const [buttonNames, SetbuttonNames] = useState<string[]>([]);
  const [buttonClicked, setButtonClicked] = useState<string | null>(null);
  const [SpecialistList, setSpecialistList] = useState(null);
  const [subSpecialistList, setSubSetSpecialistList] = useState(null);
  const [coeList, setcoeList] = useState(null);
  const [patientDetails, setpatientDetails] = useState(null);
  const [physicianList, setPhysicianList] = useState(null);
  const [dropdownNames, SetDropdownNames] = useState<string[]>([]);
  const [isfaqbutton, Setisfaqbutton] = useState(false);
  const [isPausebutton, SetisPausebutton] = useState(false);
  const [isResumebutton, SetisResumebutton] = useState(true);
  const [isvdobutton, Setisvdobutton] = useState(false);
  const [isGeneralSearch, setIsGeneralSearch] = useState(true);
  const [pageName, setPageName] = useState("");
  const [count, setCount] = useState(0);
  const [isotherQue, SetisOtherQue] = useState(false);
  const [isvideoQue, SetisVideoQue] = useState(false);
  const [isexpbutton, Setisexpbutton] = useState(false);
  const [isDictationContextNew, setIsDictationContextNew] = useState(false);
  
  const initialMessages = isPopUp ==='true' ? [] : [
    {
      text: `Hi, Dr. ${lastName}. How can I help you today?`,
      user: false,
    },
  ];
  
  const [messagesa, setmessagesa] = useState<Message[]>(initialMessages);
  const { speak, cancel, speaking, voices } = useSpeechSynthesis();
  const [isSpeechPaused, setIsSpeechPaused] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [documentURLRes, setDocumentURLRes] = useState(null);
  const [clickedButton, setClickedButton] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [pdfUrl, SetPdfUrl] = useState<string[]>([]);
  const [imageUrl, SetImageUrl] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState<string[]>([]);
  const [htmlFormat, setHtmlFormat] = useState([]);
  let pageNumber = sessionStorage.getItem("pageNumber");
  let currentLinkGpt = sessionStorage.getItem("currentLinkGpt");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFile1, setSelectedFile1] = useState<File | null>(null);
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);
  const [selectedFile3, setSelectedFile3] = useState<File | null>(null);
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const [answer, setAnswer] = useState("");
  const [threads, setThreads] = useState([]);
  const [last7DaysThreads, setLast7DaysThread] = useState([]);
  const [threadApiCalled, setThreadApiCalled] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [activeclicked, setActiveClicked] = useState(false);
  const [threadButtonClicked, setThreadButtonClicked] = useState(false);
  const [activeThread, setActiveThread] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [olderThread, setOlderThread] = useState([]);
  const [todayThread, setTodayThread] = useState([]);
  const [transcript, setTranscript] = useState("");
  const [text, setText] = useState("");
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);
  const [summaryValue, setSummaryValue] = useState("");
  const [micflag, setMicFlag] = useState(false); 
  const [documentLists, setdocumentLists] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [isdictationStarted, setIsDictationStarted] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  

  const mediaRecorderRef = React.useRef<MediaRecorder | null>(
    null as MediaRecorder | null
  );
  const streamRef = useRef<MediaStream | null>(null);
  //Image Capture

  const [image, setImage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [photoCaptured, setPhotoCaptured] = useState(false);
  const [stream, setStream] = useState(null);
 
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

const stopVideoStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null); // Clear the stream state after stopping
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    } else {
      console.log("No video stream to stop.");
    }
  };
 
  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setStream(stream); // Store the stream in state
        }
      })
      .catch((err) => {
        console.error("Error accessing the camera:", err);
      });
  }; 
  

  const captureImage = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const video = videoRef.current;
  
    // Draw the video frame onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageUrl = canvas.toDataURL("image/png");    
    setImage(imageUrl);
    // Convert the canvas image data to a Blob
    canvas.toBlob(blob => {
      if (blob) {        
        const fileName = "captured_image.png"; // Set the desired file name
        const imageFile = new File([blob], fileName, { type: "image/png" });
        file = imageFile;        
      }
    }, "image/png");
    setPhotoCaptured(true);
  };
  
  const retakePhoto = () => {
    setImage(null);
    setPhotoCaptured(false);
    startVideo();
  };
 
  const uploadImage = async () => {
    try {      
      let res = await uploadToAzure();     
        uploadFileUrl = res.data.commandResult.data.fileUrl;
        demo = `capture_name ${res.data.commandResult.data.fileName}`;
        let nContext=[
          ...context,
          {role:"system", content:`You have selected file name ${uploadFileUrl}`} ,
          {role:"user", content: demo} 
        ]
        setContext(nContext);
        // setSelectedFile(file);
        const userMessage = { text: demo, user: true };
        let aiResponse = { text: "Please wait...", user: false };
        setmessagesa((prevmessagesa) => [
          ...prevmessagesa,
          userMessage,
          aiResponse,
        ]);        
        SetbuttonNames([])        
        stopVideoStream();
        setIsDialogOpen(false);
        setImage(null);
        setPhotoCaptured(false);
        getCompletionFrommessagesa(nContext)      
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  function functionDialogOpen() {
    setIsDialogOpen(true);
  }
  
  const [isRecording, setIsRecording] = useState(false);
  const initialTime = 15 * 60; // 15 minutes
  // const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [showTimer, setShowTimer] = useState(false);
  const intervalRef = useRef(null);
  const currentDateTime = format(new Date(), "yyyyMMdd_HHmmss");
  const fileName = `${empName}_${currentDateTime}.mp3`;
  
  //speech to text conversion
  const startSpeechRecognition = () => {
    setMicFlag(true)
    const recognitions2t = new (window as any).webkitSpeechRecognition();

    recognitions2t.continuous = true;

    recognitions2t.onstart = () => {
      setIsMicrophoneOn(true);
    };

    recognitions2t.onresult = (event) => {
      const result = event.results[event.results.length - 1][0].transcript;
      setTranscript(result);
      setResponse((prevResponse) => prevResponse + ' ' + result);

    };

    recognitions2t.onend = () => {
      setMicFlag(false);
      setIsMicrophoneOn(false);
    };
    setTimeout(() => {
      // recognitions2t.stop();
      setMicFlag(false);
    }, 20000);

    recognitions2t.start();
    
  };
  let recordingTimeout: number | null = null; // Initialize recording timeout as null
  let startTime: number | null = null; // Initialize start time for accurate resume
  let pausedTime: number = 0; // Initialize paused time for accurate resume
  let alertShown = false; // Flag to track if alert has been shown
  let t:any;
 
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true }); 
      streamRef.current = stream; // Store the stream in the ref 
      const mediaRecorder = new (window as any).MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;  
      const audioChunks: Blob[] = [];
      let isRecording = true;
      let alertShown = false;  
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunks.push(e.data);
        }
      };
  
      // const recordingPromise = new Promise((resolve, reject) => {
        mediaRecorder.onstop = async () => {
          clearTimeout(recordingTimeout);
          audioBlob = new Blob(audioChunks, { type: 'audio/wav' });         
          try {            
            uploadToServer(audioBlob,isDictationContextNew)             
          } catch (error) {
            // reject(error); // Reject the promise if there is an error during upload
          }
        };
       
      // Set a timeout to stop recording after 2 minutes (120,000 milliseconds)
      const recordingTimeout = setTimeout(() => {
        if (isRecording) {
          stopRecording();
          if (!alertShown) {            
            console.log("sumres on alert", sumres);            
          }
        }
      }, 900000);
  
      mediaRecorder.start();
      setIsRecording(true);
      // setIsRecording(true);
      setShowTimer(true);
      // setTimeLeft(initialTime);
      setTimeLeft(0); 
      // Start the timer interval
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime + 1);
      }, 1000);
    }     
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false); 
      setMicFlag(false);
      setShowTimer(false); 
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null; // Clear the stream ref
      }
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current); // Clear the interval for the timer
        intervalRef.current = null; // Reset interval reference
      }    
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.pause(); // Pause the MediaRecorder
      setIsRecording(false); // Update state to indicate paused state
      clearTimeout(recordingTimeout!); // Pause the recording timeout
      startTime = Date.now(); // Record the start time to calculate paused time
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current); // Clear the timer interval
        intervalRef.current = null; // Reset interval reference
      }
    }
  };
  
  //modified on 31-07-2024
  const resumeRecording = () => {    
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "paused") {
      mediaRecorderRef.current.resume(); // Resume the MediaRecorder
      setIsRecording(true);
    let pausedTimeString = formatTimeLeft();
    if (pausedTimeString !== null) {
      let [minutes, seconds] = pausedTimeString.split(':');
      let pausedSeconds = parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
      pausedTime += pausedSeconds; // Accumulate paused time in seconds      
    }
    recordingTimeout = window.setTimeout(() => {
      stopRecording();
      if (!alertShown) {
        // alert('Your recording has reached a duration of 15 minutes. The recording will be stopped.');
        // alertShown = true;
      }
    }, (900 - pausedTime) * 1000);    
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime + 1);
      }, 1000);
    }
    }
  };  
  const formatTimeLeft = () => {
    let minutesNum = Math.floor(timeLeft / 60);
    let secondsNum = timeLeft % 60;   
     // Convert to string and pad with zeros
    let minutes: string = String(minutesNum).padStart(2, "0");
    let seconds: string = String(secondsNum).padStart(2, "0");
      return `${minutes}:${seconds}`;
  };

  const saveLocally = (audioBlob: Blob) => {
    saveAs(audioBlob, `${fileName}`);
  };

  const returnSummary = async (audioBlob: Blob) => {  
    const formData = new FormData();
    formData.append("audio", audioBlob, fileName);
    try {
      const response = await axios.post(
        `${OPENAI_API_NEW}transcribe`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );     
      let responseimg;
      
      if (uploadFileUrl !== undefined && uploadFileUrl !== "") {
        // r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", uploadFileUrl);      
        const imageBody2 = {
          img_data: uploadFileUrl,
          question: "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points",
        };
        responseimg = await axios.post(
          `${VERTEX_API}analyze_image`,
          imageBody2
        ); 
        response.data.summary = response.data.summary.replace(/{|}/g, '').replace(/```/g, '').replace(/"example_output":*/g, '')
        return `       
        <b>Autoscribe Summary:\n</b> ${response.data.summary}
        <b>Image Summary:\n</b> ${responseimg.data.answer}\n\n
        \n\nDo you need any other help?      
        `;
      }
      if (!response || !response.data || !response.data.summary) {
        // Handle case when response or summary is undefined
        response.data = response.data || {}; // Ensure response.data exists
        response.data.summary = "Unable to generate the summary of audio...Please try again.";
      } else{
      response.data.summary = response.data.summary.replace(/{|}/g, '').replace(/```/g, '').replace(/"example_output":*/g, '')
      return `       
          <b>Autoscribe Summary:\n</b> ${response.data.summary}          
          \n\nDo you need any other help?      
      `;     
      }
    }catch(error){
      console.error("Error in generating summary of audio file:", error);
    }
    
  }
  const encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result.split(',')[1]);
            } else {
                reject(new Error('FileReader returned a non-string result'));
            }
        };
        reader.onerror = error => reject(error);
    });
}; 
  
  const uploadToServer = async (audioBlob: Blob, isDictationContextNew=false) => {
    const formData = new FormData();
    formData.append("audio", audioBlob, fileName);
    let commentId;
    // setIsLoading(true);
    try { 
      let aiResponse;
      let userMessage;
      // Step 1: Set initial messages
    aiResponse = {
      text: "please wait.... uploading the dictation...",
      user: false,
    };
    userMessage = {
      text: "Stop Dictation",
      user: true,
    };
    setmessagesa((prevmessagesa) => [
      ...prevmessagesa,
      userMessage,
      aiResponse,
    ]);
      const audioFile = createAudioFile(audioBlob);  
      const audioBase64 = await encodeFileToBase64(audioFile);  
      const jsonData = createJsonData(audioFile.name, audioBase64, '','','','');
      const initialUrl = await postToApi(ApiHelper.uploadRecording, jsonData);
  
      commentId = await saveComment(initialUrl.audioUrl);
     // Conditionally show the success message based on context
     if (isDictationContextNew) {
      aiResponse = {
          text: "Dictation Uploaded successfully..... ",
          user: false,
      };
      setmessagesa((prevmessagesa) => [
          ...prevmessagesa.slice(0, -1), // Remove the last "please wait..." message
          aiResponse,
      ]);
  }
     

      
      sessionStorage.setItem(`commentId`, commentId);
      sessionStorage.setItem(`transcribeStatus`, 'pending');
      
      sessionStorage.setItem('transcriptionInProgress', JSON.stringify({ 
        recordingUrl: initialUrl.audioUrl,  // Corrected key to recordingUrl
        commentId: commentId                // Corrected commentId
      }));
      const transcribeResponse = await transcribeAudio(formData);
      
      let { original_text_content, summary } = transcribeResponse;
      // Removing curly braces from the original_text_content if present
      original_text_content = original_text_content.replace(/{|}/g, '').replace(/```/g, '')                
      .replace(/"example_output":*/g, '')   // Remove the "example output" label          
      
      setSummaryValue(summary); 
      if(transcribeResponse)      
      {     
      const transcriptFile = createTextFile(original_text_content, `${fileName}_transcription.txt`);
      const summaryFile = createTextFile(summary, `${fileName}_summary.txt`);
  
      const transcriptBase64 = await encodeFileToBase64(transcriptFile);
      const summaryBase64 = await encodeFileToBase64(summaryFile);
  
      const secondJsonData = createJsonData('', '', transcriptFile.name, transcriptBase64, summaryFile.name, summaryBase64);
      const secondUrl = await postToApi(ApiHelper.uploadRecording, secondJsonData);
  
      await updateComment(commentId, secondUrl.transcriptUrl, secondUrl.summaryUrl);
     
        sessionStorage.setItem(`commentId`, commentId);  
        sessionStorage.setItem(`transcribeStatus`, 'success');     
        window.dispatchEvent(new CustomEvent('transcribeStatusUpdate', { detail: { status: 'success', transcriptUrl: transcribeResponse.transcriptUrl,message:'Transcribe is ready' } }));        
      }
      else
      {        
        
        sessionStorage.setItem(`commentId`, commentId);
        sessionStorage.setItem(`transcribeStatus`, 'error');
        window.dispatchEvent(new CustomEvent('transcribeStatusUpdate', { detail: { status: '400 bad request',message:'Unable to understand audio... Failed to generate Transcribe' } }));        
      }   
      sessionStorage.removeItem('transcriptionInProgress');
    } catch (error) {      
      sessionStorage.setItem(`commentId`, commentId);
      sessionStorage.setItem(`transcribeStatus`, 'error');
      window.dispatchEvent(new CustomEvent('transcribeStatusUpdate', { detail: { status: '400 bad request',message:'Unable to understand audio... Failed to generate Transcribe' } }));     
    }
  }; 
  
  const createAudioFile = (blob) => new File([blob], `${fileName}.mp3`, { type: "audio/mp3" });
  
  const createJsonData = (audioFileName, audioBase64, transcriptFileName, transcriptBase64, summaryFileName, summaryBase64) => ({
    company_slug: ConstantHelper.COMPANY_SLUG,
    physician_id: loggedInUserId,
    patient_id: patient_id,
    chat_id: `${thread_id}`,
    audioKey: { filename: audioFileName, content: audioBase64 },
    transcriptKey: { filename: transcriptFileName, content: transcriptBase64 },
    summaryKey: { filename: summaryFileName, content: summaryBase64 }
  });
  
  const postToApi = async (endpoint, data) => {
    const apiurl = sls06baseurl + endpoint;
    const result = await axios.post(`${apiurl}`, data);
    return result.data.commandResult.data;
  };
  
  const saveComment = async (audioUrl) => {
    const initialSaveDictationUrls = {
      comments: "Dictation",
      loggedInUserId: loggedInUserId,
      patientId: patient_id,
      adminId: loggedInUserId,
      companySlug: ConstantHelper.COMPANY_SLUG,
      recordingUrl: audioUrl,
      summaryUrl: '',
      transcriptUrl: '',
    };
  
    const apiurl = sls05baseurl + ApiHelper.saveComment;
    const result = await axios.post(`${apiurl}`, initialSaveDictationUrls);    
    return result.data.commandResult.data.commentId;
  };
  
  const transcribeAudio = async (formData) => {
    try {      
      const response = await axios.post(`${OPENAI_API_NEW}transcribe`, formData, {       
        headers: { "Content-Type": "multipart/form-data" },
      });
      if(response && response.data)
      {      
      return response.data;
      }
      else
      {
        SweetAlertHelper.alertPopUp("Failed to upload audio file to server....Please Try again...");
      }
    } catch (error) {
      console.error("Error transcribing audio:", error);
      throw new Error("Failed to transcribe audio");
    }
  };
  
  const createTextFile = (content, fileName) => new File([new Blob([content], { type: 'text/plain' })], fileName, { type: "text/plain" });
  
  const updateComment = async (commentId, transcriptUrl, summaryUrl) => {
    const updateDictationUrls = { commentId, transcriptUrl, summaryUrl };
    const apiurl = sls05baseurl + ApiHelper.updateComment;
    await axios.post(`${apiurl}`, updateDictationUrls);
  };

  useEffect(() => {
    // Check if the query param 'startRecording' is present in the URL
    const params = new URLSearchParams(window.location.search);
    const shouldStartRecording = params.get('startRecording') === 'true';

    if (shouldStartRecording) {
      handleStaticButton("start dictation"); // Call your startRecording function here
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const clickpatientdetails = params.get('Patientdetails') === 'true';

    if (clickpatientdetails) {
      handleStaticButton("patient details"); 
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const respondreferral = params.get('respondexpert2ndopinion') === 'true';

    if (respondreferral) {
      handleStaticButton("respond expert 2nd opinion"); 
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const clickvideo = params.get('uploadvideo') === 'true';

    if (clickvideo) {
      handleStaticButton("upload video"); 
    }
  }, []);
  
  useEffect(() => {    
    const status = sessionStorage.getItem('transcribeStatus');
  }, []);

  useEffect(() => {
    
  }, [isLoading]); 

  useEffect(() => {
    if (showTimer) {
      intervalRef.current = setInterval(() => {        
        setTimeLeft((prevTime) => prevTime + 1);
      }, 1000);
    }else {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }   
  return () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };
  }, [showTimer]);

  useEffect(() => {
    if (!isRecording) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [isRecording]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [filename1, setFilename1] = useState<string | null>(null);
  const [filename2, setFilename2] = useState<string | null>(null);
  const [filename3, setFilename3] = useState<string | null>(null);
  const [showFileUpload, SetShowFileUpload] = useState(false);

  useEffect(() => {    
  }, [isSidebarOpen]);  

  useEffect(() => {    
    if(isPopUp && patientId){        
        popres = "start dictation"   
        handleSendMessage();
        setIsDictationContextNew(true);
      }      
  }, [patientId]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const question = params.get('question'); // Retrieve 'question' query parameter
    if (question) {
      setResponse(decodeURIComponent(question)); // Decode the parameter to handle special characters
    }
  }, []);
  

  let loggedInUserId = localStorage.getItem("loggedInUserId");
  let loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
  let loggedInUserMobile = localStorage.getItem("loggedInUserMobile");
  let formattedPhoneNumber = `(${loggedInUserMobile.slice(
    0,
    3
  )}) ${loggedInUserMobile.slice(3, 6)}-${loggedInUserMobile.slice(6)}`;
  let loggedInUserName = localStorage.getItem("loggedInUserName");
  let doctorToDoctorEmailPendingCount = localStorage.getItem(
    "doctorToDoctorEmailPendingCount"
  );
  let physicianSecondOpinionPendingCount = localStorage.getItem(
    "physicianSecondOpinionPendingCount"
  );
  let patientSecondOpinionPendingCount = localStorage.getItem(
    "patientSecondOpinionPendingCount"
  );
  let expertSecondOpinionPendingCount = localStorage.getItem(
    "expertSecondOpinionPendingCount"
  );
  let currentURL = localStorage.getItem("currentURL");
  let baseURL = localStorage.getItem("baseURL");
  let loggedInUserEmail = localStorage.getItem("loggedInUserEmail");
  let sls05baseurl = environment.lambdaApiUrl_05;
  let sls03baseurl = environment.lambdaApiUrl_03;
  let sls04baseurl = environment.lambdaApiUrl_04;
  let sls06baseurl = environment.lambdaApiUrl_06;
  let phpbaseurl = environment.phpApiUrl;
  let envName = environment.environmentName;
  let OPENAI_API = openaiEnv.OPENAI_API;
  let ACS_API = openaiEnv.ACS_API;
  let VERTEX_API = openaiEnv.VERTEX_API;
  let OPENAI_API_NEW = openaiEnv.OPENAI_API_NEW;  
  const initialContext = [
    {
      role: "system",
      content: `
      You are a friendly physician Assistance ChatBot, first greet the user with their last name Dr.${lastName} (note : take only last name with adding initial as "Dr." and greet).`,
    },
    {
      role: "user",
      content: "Hi",
    },
  ];
  const [context, setContext] = useState(initialContext);  
  const hasMessages = (threads) => {
    return true; // Replace with your actual logic
  };

  const handleCopyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setCopiedIndex(index))
      .catch((err) => console.error("Unable to copy to clipboard", err));
  };

  const handleVolumeIconClick = (text) => () => {
    if (speaking) {
      if (isSpeechPaused) {
        speak({ text: text, voice: voices[4] });
        setIsSpeechPaused(false);
      } else {
        // Pause speech synthesis
        cancel();
        setIsSpeechPaused(true);
      }
    } else {
      // Start speech synthesis
      speak({ text: text, voice: voices[4] });
      setIsSpeechPaused(false);
    }
  };

  const send_msg_func = async (req, respo, id) =>{
    const obj2 = {
      request: req,
      response: respo,
      threadId: id,
    };
    const add_msg = await axios.post(
      `${ACS_API}send_msg`,
      obj2
    );
  };

  const categorizeThreads = (threads_list) => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const todayThreads = [];
      const last7DaysThreads = [];
      const olderThreads = [];

      threads_list.forEach((thread) => {
        const threadDate = new Date(thread.date);
        const last7DaysDate = new Date(today); 
        last7DaysDate.setDate(today.getDate() - 7);
        if (threadDate >= today) {
          todayThreads.push(thread);
        } else if (threadDate >= last7DaysDate) {
          last7DaysThreads.push(thread);
        } else {
          olderThreads.push(thread);
        }
      });
      todayThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      last7DaysThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      olderThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setTodayThread(todayThreads);
      setLast7DaysThread(last7DaysThreads);
      setOlderThread(olderThreads);      
    } catch (error) {
      console.log("Error:-", error);
    }
  };

  const get_all_threads = async () => {
    try {
      const response = await axios.get(
        `${ACS_API}get_all_threads/${loggedInUserEmail}`
      );
     
      const names = response.data;
      const id = names.id;
      categorizeThreads(names);
      const transformedMessages = names.map((item) => [item.name, item.id]);    
      setThreads(transformedMessages);   
    } catch (error) {
      console.error("Error: ", error);
    }
  };


  const loadThread = async (thread, index) => {
    handleButtonClick();
    setButtonClicked("Start new Conversation");
    setIsButtonDisabled(false);
    setThreadButtonClicked(true);
    setActiveThread(index);
    try {
      const t_id = thread.id;
      const name = thread.name;
      const response = await axios.get(
        `${ACS_API}get_full_thread/${t_id}`
      );      
      const chat = response.data;
      setButtonClicked("Start new Conversation");
      setIsButtonDisabled(false);
      const id = chat.row;
      const transformedMessages = chat.map((item) => {
        const userMsg = { text: item.question, user: true };
        let aiResponse = { text: item.answer, user: false };
        return [userMsg, aiResponse];
      });
      // Flatten the array of arrays into a single array
      const flattenedMessages = [].concat(...transformedMessages);
      try {
        setmessagesa(flattenedMessages);
      } catch (err) {
        console.log("error: ", err);
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleButtonClick = () => {
    setClicked(!clicked);
  };

  const resetAllData = () => {
    SweetAlertHelper.showConfirm(
      "Are you sure want to reset all the data?",
      () => {
        window.location.reload();
      }
    );
  };

  const extractButtonNames = (text: string) => {
    const startIndex = text.indexOf("Buttons: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      return buttonString.split("', '");
    }
    return [];
  };

  const extractDropdownopt = (text: string) => {
    const startIndex = text.toLowerCase().indexOf("dropdown: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      const options = buttonString
        .split("', '")
        .map((option) => option.replace(/'/g, ""));
      return options;
    }
    return [];
  };

  const getAllDocumentsAPI = async () => {
    let apiurl = sls03baseurl + ApiHelper.name_findAllDocumentsApi;
    const body = {
      company_slug: null,      
      loggedInUserId: patient_id,      
    };

    try {
      const res = await axios.post(        
        `${apiurl}`,
        body
      );
      const { data } = res; // Use res.data directly
      const allDocumentsData = res.data.commandResult.data.documentList.map(
        (item) => ({
          RecordId: item.RecordId,
          DocumentName: item.DocumentName,
          DocumentUrl: item.DocumentUrl,
        })
      );
      const allDocumentData = allDocumentsData.slice(0, 25);
      let nContext = [
        ...context, 
        {role: "user", content: demo},       
        {
          role: "system",
          content: `here are the documents of patient  ${JSON.stringify(allDocumentData)}`,
        },
      ];
      setContext(nContext)
      setdocumentLists(allDocumentData);      
      return allDocumentData;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {   
    initialquestionCount();    
    specialist_listapi();    
    get_all_threads();
  }, []);

  const specialist_listapi = async () => {
    let apiurl = sls05baseurl + ApiHelper.getSpecialties;   
    try {
      const body = {
        loggedInUserId: loggedInUserId,
      };
      const res = await axios.post(        
        `${apiurl}`,
        body
      );
      const { data } = res;
      const specialistData = res.data.commandResult.data.specialities.filter(item => item.is_active === 1).map(
        (item) => ({
          id: item.id,
          speciality: item.speciality,
        })
      );      
      setSpecialistList(specialistData);
      return specialistData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const subSpecialist_listapi = async (speciality: string) => {
    let apiurl = sls05baseurl + ApiHelper.getSubSpecialties;   
    try {
      const body = {
        speciality: speciality,
      };
      const res = await axios.post(        
        `${apiurl}`,
        body
      );
      const { data } = res;
      subSpecialistNames = res.data.commandResult.data.sub_specialities.map(
        (item) => ({
          id: item.id,
          sub_speciality: item.sub_speciality,
        })
      );
      setSubSetSpecialistList(subSpecialistNames);      
      return subSpecialistNames;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const getfaqfirst = async () => {
    let updatedurl = currentURL.replace(/-/g, " ");   
    let parts = currentURL.split("/");
    if (parts[1] == "ahsgpt") {
      setPageName(parts[1]);
    } else {
      setPageName(parts[2]);
    }
    let pagenameFirst = parts[2].replace("-", " ");
    try {
      const body = {
        question: pagenameFirst,
        loggedInUserRoleId: loggedInUserRoleId
      };
      const res = await axios.post(
         `${OPENAI_API_NEW}faq_search`,        
        body
      );
      if (res.data && res.data.answer) {
        setDocumentURLRes(res.data.answer);
      } else {
        console.error("API response is missing 'answer'.", res);
      }
    } catch (err) {
      console.error("Error:", err);
      return [];
    }
  };

  useEffect(() => {
    if(!isPopUp){
    getfaqfirst();
    }
  }, []);

  const coe_listapi = async (speciality: string, subSpecialty: string) => {
    let apiurl = sls05baseurl + ApiHelper.getCoeBySubSpeciality;
    try {
      const body = {
        speciality: speciality,
        subSpecialty: subSpecialty,
      };
      const res = await axios.post(       
        `${apiurl}`,
        body
      );
      const { data } = res;
      const coeList = res.data.commandResult.data.practices.map((item) => ({
        id: item.id,
        company: item.company,
      }));
      setcoeList(coeList);      
      return coeList;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const physician_listapi = async (
    speciality: string,
    subSpeciality: string,
    companySlug: string
  ) => {
    let apiurl = sls05baseurl + ApiHelper.getCoePhysicians;    
    try {
      const body = {
        speciality: speciality,
        subSpeciality: subSpeciality,
        companySlug: companySlug,
      };
      const res = await axios.post(
        // "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getCoePhysicians",
        `${apiurl}`,
        body
      );
      const { data } = res;
      const physicianList = res.data.commandResult.data.physicians.map(
        (item) => ({
          registered_physician_id: item.registered_physician_id,
          full_name: item.full_name,
        })
      );
      setPhysicianList(physicianList);
      // const physicianList = JSON.stringify(data);
      return physicianList;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  
  const extractsecondOpinionjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);    
    SecondOpinionjsonObject = JSON.parse(jsonString);    
  };

  const extractuploadVideojson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    UploadVideojsonObject = JSON.parse(jsonString);
  };
  const extractpriscriptionjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("[");
    const jsonEndIndex = text.indexOf("]", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    UploadPrescriptionObject = JSON.parse(jsonString);    
    return UploadPrescriptionObject
  };

  const extractpatientidjson = async (text) => {
    const userIdRegex = /"userId": "(\d+)"/;
    const match = text.match(userIdRegex);
    const userId = match ? match[1] : null;
    patient_id = userId;
    return userId
  };

  const extractdenialjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    const json = JSON.parse(jsonString);
    const reason = json.reason;   
  };

  const handleUploadVideoAPI = async (hipaa_value) => {
    try {
      let company= ConstantHelper.COMPANY_SLUG;      
      azureUploadResponse = await uploadToAzure();
      uploadVideoFileName = azureUploadResponse.data.commandResult.data.fileName;
      if (hipaa_value == 10) {        
        uploadVideoFileUrl = azureUploadResponse.data.commandResult.data.fileUrl;
      }
      if (hipaa_value == 9) {
        uploadFileUrl = azureUploadResponse.data.commandResult.data.fileUrl;
      }      
      const uploadVideoRes = await uploadVideoToPatient(hipaa_value,company);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const uploadToAzure = async () => {
    let res    
    const azureBody = new FormData();
    azureBody.append("loggedInUserId", patient_id);
    azureBody.append("fileKey", file);
    try{
      const azureApiUrl = phpbaseurl + ApiHelper.php_uploadAzureFile;
      res= await axios.post(azureApiUrl, azureBody);      
    } catch(err){
      console.error("Error:", err);
    }
    return res;
    
  };
  

  const generateVideoURI = async (que) => {    
    try {
     
      if(uploadVideoFileUrl !== undefined){        
        let modified_video_question = '';

        if (prevVideoSummary != '') {
          modified_video_question = "This is the previous summary that you have generated \n" + prevVideoSummary + "\n\n" + que;
        } else {
          modified_video_question = que;
        }

        let videoBody = {
          video_data: uploadVideoFileUrl,
          video_question: modified_video_question          
        };        
        let res = await axios.post(
          `${VERTEX_API}analyze_video`,
          videoBody
        );
        let response=res.data.answer;
        response = response.replace(/```html/g, "").replace(/```/g, "").trim(); // Remove ```html
        if (response.toLowerCase().includes(' good') || response.toLowerCase().includes(' bad')) {          
          let secondResponse = await axios.post(`${OPENAI_API_NEW}get_ai_res_summary`, { prompt: videoBody.video_question })
              return secondResponse.data.answer                          
        } else {          
          return response;
        }
      }else{
        
        let r1;
        if (pdfUrl.length > 0) {         
            r1 = "Anatomical abnormalities would be structural changes or irregularities in the body, which are usually identified through imaging studies like X-rays, MRI, CT scans, or physical examinations, not through lab results."         
        }
        if (imageUrl.length > 0) {
          if (imageUrl.length !== 1){
            r1 = "Please select only one document check anatomical abnormalities!"
          }else{
            r1 = await report(que, imageUrl);
            SetisOtherQue(true);
          }
        }
        if(videoUrl.length > 0){          
          r1 = await report(que, videoUrl);          
          SetisOtherQue(true);
        }           
        return r1      
      }      
    }
    catch (error) {
      console.error("Error:", error);
      return "Something went wrong! Please try again..."
    }
  };

  let company=ConstantHelper.COMPANY_SLUG;

  const getCompanyDetails1 = async (company) => {
    let res    
    const companyBody = {
      "company_slug": company
  };
    try{
      const companyApiUrl = sls05baseurl + ApiHelper.getCompanyDetailsByCompSlug;      
      const res = await axios.post(        
        `${companyApiUrl}`,
        companyBody
      );      
    } catch(err){
      console.error("Error:", err);
    }
    return res;
  }  
  const uploadVideoToPatient = async (hipaa_value,company) => {    
    const response = await getCompanyDetails1(company);
    const userInfo = response.data.commandResult.data.userInfo;    
    let apiurl = phpbaseurl + ApiHelper.php_uploadEsignMultipleFiles;   
    const body = {
      "userId": patient_id,
      "isDelegating": null,
      "delegateId": null,
      "parentId": null,
      "isUploadFile": true,      
      "data": [
          {
              "RecordId": 0,
              "UploadedPracticesListSelected": [],
              "UploadedPhysicanListSelected": [
                  {
                      "doctor_id": "435",
                      "id": "1",
                      "physician_id": loggedInUserId,                      
                      "company":ConstantHelper.COMPANY_NAME,                      
                      "company_domain":ConstantHelper.COMPANY_DOMAIN,                     
                      "company_url":ConstantHelper.COMPANY_SLUG,                      
                      "company_phone": userInfo.company_phone,                      
                      "company_email":userInfo.company_email,
                      "first_name":userInfo.first_name,
                      "last_name": userInfo.last_name,
                      "address":userInfo.address,
                      "full_address":userInfo.full_address,                      
                      "physician_email": loggedInUserEmail,
                      "physician_mobile": loggedInUserMobile,
                      "physician_name": empName,                      
                      "physician": empName+ ConstantHelper.COMPANY_NAME
                  }
              ],
              "practiceInfo": "",
              "isHippa": hipaa_value,
              "file": uploadVideoFileUrl,
              "fileName": uploadVideoFileName
          }
      ],
      "docType": 4
  }
    try {
      const res = await axios.post(       
        `${apiurl}`,
        body
      );     
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  const handleDropdownChange = async (event: any) => {
    demo = event.target.value;
    let nContext;
    if (specialistName === "") {
      specialistName = event.target.value;      
      const foundObject = SpecialistList.find(item => item.speciality === specialistName);
      let specialistid = "";
      if (foundObject) {
        specialistid = foundObject.id;
      } else {
        specialistid = specialistName; // Or handle the case where the name is not found
      }
      let subspelist = await subSpecialist_listapi(specialistid);
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is subSpecialistNames  ${JSON.stringify(subspelist)}`,
        },
      ];
    } else if (sub_specialistName === "") {
      sub_specialistName = event.target.value;
      let coenamelist = await coe_listapi(specialistName, sub_specialistName);
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(coenamelist)}`,
        },
      ];
    } else if (practice_name === "") {
      practice_name = event.target.value;
      if (practice_name.includes("Princeton")) {        
        practice_name = ConstantHelper.COMPANY_SLUG;
      } else if (practice_name.includes("HITLAB Surgical")) {
        practice_name = "hitlab";
      }
      let physicianlistname = await physician_listapi(
        specialistName,
        sub_specialistName,
        practice_name
      );
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(physicianlistname)}`,
        },
      ];
    } else if (physician_name === "") {
      physician_name = event.target.value;
      nContext = [...context, { role: "user", content: event.target.value }];
    } else {
      nContext = [...context, { role: "user", content: event.target.value }];
    }
    setContext(nContext);
    const userMessage = {
      text: event.target.value,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa((prevmessagesa) => [
      ...prevmessagesa,
      userMessage,
      aiResponse,
    ]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
    SetDropdownNames([]);
  };

  const getPatientDetails = async () => {
    let apiurl = sls05baseurl + ApiHelper.getUsersListByPhysicianId;   
    const body = {     
      company_slug: ConstantHelper.COMPANY_SLUG,  
      loggedInUserId: loggedInUserId,
    };

    try {
      const res = await axios.post(
        // "https://api.medrecordexchange.com/dev/getUsersListByPhysicianId.php",
        `${apiurl}`,
        body
      );
      patientData = res.data.commandResult.data.demographicUsers.map(
        (item) => ({
          UserId: item.DemographicId,
          Name: `${item.from1FirstName} ${item.from1LastName}`,
          Email: item.Email,
          Mobile: item.Mobile,
          Address: `${item.form1StreetName}, ${item.form1City}, ${item.form1State}, ${item.form1ZIP}`,
        })
      );      
      setpatientDetails(patientData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  
  const getPatientDetailsById = async () => {
    let apiurl = sls05baseurl + ApiHelper.getPatientDetailsById;    
    const body = {
        patientId: patientId
    };
    try {
        const res = await axios.post(
            `${apiurl}`,
            body
        );        
        const patientInfo = res.data.commandResult.data.patientInfo;
        if (patientInfo) {
            const patientData = {
                UserId: patientId,
                Name: `${patientInfo.FirstName} ${patientInfo.LastName}`,
                Email: patientInfo.Email,
                Mobile: patientInfo.Mobile,
                Address: `${patientInfo.Address}, ${patientInfo.City}, ${patientInfo.State}, ${patientInfo.Zip}`
            };            
            patientdictdata = patientData;
            patientDetailspop = patientData;
            setpatientDetails(patientDetailspop);
            return patientdictdata
        } else {
            console.warn("No patient data found for the provided patientId");
        }
    } catch (error) {
        console.error("Error:", error);
    }
};
  window.test = (btnName) => {
    demo = btnName;    
    if(demo == "Top 5 Documents"){      
      SetbuttonNames(['Compare', 'Summarize', 'Anatomical Abnormalities', 'Other Question', 'Reset'])
      let aiResponse = { text: html_table, user: false };
      let userMessage = { text: demo, user: true };
      setmessagesa([...messagesa, userMessage, aiResponse]);     
   
  }
  else{
    let nContext = [
      ...context,
      { role: "user", content: "Start dictation" },
    ];
    setContext(nContext);
    const userMessage = {
      text: "Start Dictation",
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  
  }
  };
  const report = async (user_question, url) => {
    let response;
    const isPdf = url.every(
      (url) =>
        url.toLowerCase().endsWith(".pdf") ||
        url.toLowerCase().endsWith(".docx")
    );
    const isImage = url.every(
      (url) =>
        url.toLowerCase().endsWith(".jpg") ||
        url.toLowerCase().endsWith(".jpeg") ||
        url.toLowerCase().endsWith(".dcm") ||
        url.toLowerCase().endsWith(".dicom")||
        url.toLowerCase().endsWith(".png")
    );
    const isVideo = url.every(
      (url) => 
      url.toLowerCase().endsWith(".webm") ||
      url.toLowerCase().endsWith(".mp4")
    );
    try {
      if (isPdf) {
        // Create a separate body object for the PDF API call
        const pdfBody = {
          question: user_question,
          pdf_blob_urls: url
        };

        response = await axios.post(
          `${OPENAI_API_NEW}reports`,
          pdfBody
        );
      }
      else if (isImage) {
        let urlone = url[0]
        // Create a separate body object for the image API call
        const imageBody = {
          img_data: urlone,
          question: user_question,
        };

        response = await axios.post(
          `${VERTEX_API}analyze_image`,
          imageBody
        );
      } else if(isVideo){
        if(url.length !== 1){
          return "Please select only one video url to summarize"
        }else{
          let urlone = url[0];
          const videoBody = {
            video_data: urlone,
            video_question:user_question
          };  
          response = await axios.post(
            `${VERTEX_API}analyze_video`,
            // `http://127.0.0.1:5000/generate_uri`,
            videoBody,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
        }
        }
      else if(!isPdf && !isImage && !videoUrl){
        let urlone = url[0]
        const imageBody = {
          img_data: urlone,
          question: user_question,
        };
        response = await axios.post(
          `${VERTEX_API}analyze_image`,
          imageBody
        );
      }
      if (response) {        
        return response.data.answer;
      } else {
        console.log("No valid API call was made.");
      }
    } catch (error) {
      console.error("Error calling Python API:", error.message);
    }
  };
  window.demoTest = (url) => {
    SweetAlertHelper.showConfirm(
      "Are you sure you want to open this documents in new tab?",
      () => {
        window.open(url, "_blank");
      }
    );
  };

  window.printDocumentUrl = (url) => {
    console.log("condition: ", url.toLowerCase().endsWith(".pdf") ||
    url.toLowerCase().endsWith(".docx"));
    if (
      url.toLowerCase().endsWith(".pdf") ||
      url.toLowerCase().endsWith(".docx")
    ) {
      SetPdfUrl([...pdfUrl, url]);
    } else if (
      url.toLowerCase().endsWith(".jpg") ||
      url.toLowerCase().endsWith(".jpeg") ||
      url.toLowerCase().endsWith(".png") ||
      url.toLowerCase().endsWith(".")
    ) {
      SetImageUrl([...imageUrl, url]);
    } else if(
      url.toLowerCase().endsWith(".webm") ||
      url.toLowerCase().endsWith(".mp4")
    ) {
      setVideoUrl([...videoUrl, url]);      
    }
    else {
      console.error("Invalid PDF URL:", url);
    }
  };

  window.testRespond = (RecordId) => {
    demo = "Send Response";
    let nContext = [
      ...context,
      {
        role: "system",
        content: `Physician selected this RecordID ${RecordId}`,
      },
      { role: "user", content: "Send Response" },
    ];
    setContext(nContext);
    const userMessage = {
      text: "Send Response",
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  };

  window.testUpdatedRespond = (btntxt) => {
    demo = btntxt;
    // setResponse("btntxt");
    let nContext = [...context, { role: "user", content: btntxt }];
    setContext(nContext);
    const userMessage = {
      text: btntxt,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  };


  window.testAutoRespond = (btntext) => {
    demo = btntext;
    // setResponse(btntext);
    let nContext = [...context, { role: "user", content: btntext }];
    setContext(nContext);
    const userMessage = {
      text: btntext,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  };

  const extractDocumentjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    Documentjson = JSON.parse(jsonString);
    var doc = await getPatientDocuments(Documentjson);
    let dContext = [
      ...context,
      {
        role: "system",
        content: `user has provided html code in variable HTMLCODE: '''${doc}''', you need to return code without any change as output(note: Do not include  HTMLCODE: and quotes). for ex: HTMLCODE: '''<html><b> Hello</b>''' then return output as <html><b> Hello</b>`,
      },
    ];
    setContext(dContext);
    return Documentjson;
  };

  const getPatientDocuments = async (Documentjson) => {
    let apiurl = sls05baseurl + ApiHelper.getTopDocumentsList;   
    try {
      const res = await axios.post(        
        `${apiurl}`,
        Documentjson
      );
      patientDoc = res.data.commandResult.data.documentList.map((item) => ({
        DocumentName: item.DocumentName,
        DocumentUrl: item.DocumentUrl,
        DocumentDescription: item.DocumentDescription,
      }));
      const obj={
        'docs': patientDoc
      }
      const table_response = await axios.post(
        // `http://127.0.0.1:5000/get_doc_table`,
        `${OPENAI_API_NEW}get_doc_table`,
        obj);
      html_table = table_response.data.assres;
      checkboxtable = html_table
      setHtmlFormat(html_table);
      return html_table;
    } catch (error) {
      console.error("Error:", error);
    }
  };

window.generatePDF = (UserId) => {
  demo = "Generate PDF";
  let nContext = [
      ...context,
      {
          role: "system",
          content: `Physician selected this UserId ${UserId}`,
      },
      { role: "user", content: "Generate PDF" },
  ];
  setContext(nContext);
  const userMessage = {
      text: "Generate PDF",
      user: true,
  };
  let aiResponse = {
      text: "Please wait, generating PDF...",
      user: false,
  };
  setmessagesa([...messagesa, userMessage, aiResponse]); 
  getCompletionFrommessagesa(nContext);
};

  const pendingReferalOpinionAPI = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_findOpinionLogApi;
    const body = {
      userId: loggedInUserId,
      logType: "1",
    };
    try {
      const res = await axios.post(        
        `${apiurl}`,
        body
      );
      pendingReferalOpinionList = res.data.commandResult.data.logs
        .filter(
          (item) => item.HasResponse === 0 && item.ResponseDateTime === ""
        )
        .map((item) => ({
          RecordId: item.RecordId,
          PatientName: item.PatientName,
          RequestDateTime: item.RequestDateTime,
          RequestingDoctorName: item.RequesitngDoctorName,
          RequestingDoctorEMail: item.RequesitngDoctorEmail,
          ResponseRequest: item.ResponseRequest,
        }));
      return pendingReferalOpinionList;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getPatientDetails();    
    pendingReferalOpinionAPI();
  }, []);

  const extractpendingsecondOpinionjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    PendingSecondOpinionjsonObject = JSON.parse(jsonString);
  };

  const sendResponseToSecondOpinionAPI = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_sendD2dResponse;
    try {
      const body = {
        logId: PendingSecondOpinionjsonObject.logId,
        emailText: PendingSecondOpinionjsonObject.emailText,
        behaviourId: PendingSecondOpinionjsonObject.behaviourId,
      };
      let result = await axios.post(        
        `${apiurl}`,
        body
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  

  const handleReportSelectAPI = async () => {    
    const body = new FormData();

    body.append("loggedInUserId", patientData.UserId);
    body.append("fileKey", file);
    let apiurl = phpbaseurl + ApiHelper.php_uploadAzureFile;
    try {
      const res = await axios.post(        
        `${apiurl}`,
        body
      );      
      let filenameup = res.data.commandResult.data.fileName;
      let fileurl = res.data.commandResult.data.fileUrl;
      const obj={
        "pdf_blob_urls":[
          fileurl
        ]
    }
      r1= await axios.post(`${OPENAI_API_NEW}pdf_to_json`,obj);      
      const answerArray = r1.data.answer; 
      const rjsonString = JSON.stringify(answerArray);      
      return rjsonString;   
    } 
    catch (error) {
      console.error("Error:", error);
    }
  };

  const getCompletionFrommessagesa = async (ncontext: any[]) => {  
    if (
      !demo.toLowerCase().includes("start new conversation") &&
      !demo.toLowerCase().includes("reset") &&
      !demo.toLowerCase().includes("exit chat")
    ) {
      questionCount();
    }
    
      try {
        const body = {
          ncontext: ncontext,
          user_id: loggedInUserId
        };
        let res = await axios.post(
          `${OPENAI_API_NEW}get_ai_res`,          
          body
        );
        let assres = res.data.assres;
        let answer = res.data.assres;
        let pdfData;
        let reportres
        setAnswer(answer);       
        
        if (assres !== null) {
          if (assres.includes("checkbox")) {
            checkboxtable = assres.split("Buttons:")[0];
          }
          if (assres.includes("userDoc-data")) {
            await extractDocumentjson(assres);
          }
         
          
          if (assres.includes("ask your questions")) {
            // console.log("user question", response, demo)
          }
          if (demo === "Yes upload" || demo === "Yes upload video" || demo==="Yes, claim" || demo === "Upload Existing Image") {
            SetShowFileUpload(true);                     
          }         
          if (demo.includes("Continue") || demo.includes("No") || assres.includes("maximum") || assres.includes("3")) {
            SetShowFileUpload(false);
            // setSelectedFile(null);
          }
          if(demo === "Continue upload" || demo.includes("file_name")){
            SetShowFileUpload(false);
            await handleUploadVideoAPI(10);

          }
          if (demo.includes("image_name")){
            await handleUploadVideoAPI(9);
          }
          if (demo.includes("Process Claim")|| demo.includes("services_name")) {

            SetShowFileUpload(false);
            reportres = await handleReportSelectAPI();
            // setSelectedFile(null);
          }        
          if (assres.toLowerCase().includes("revenue code")) {            
            let pdfres=assres;
            const body={
              payload: pdfres
            }
            pdfData = await axios.post(
            `${OPENAI_API}generate_insurance_claim_pdf`,
              body
            );
            let nContext=[
              ...context,
              {role:"system", content:`Here is your pdf link ${pdfData.data.pdf_url}`} 
            ]
          setContext(nContext);
            }
        // }
          if (demo === "I confirm") {
            if (PendingSecondOpinionjson != null) {
              sendResponseToSecondOpinionAPI();
            }
            if (SecondOpinionjson != null) {
              handleFileSelectAPI();
            }
            if (SecondOpinionjson1 != null) {
              handleFileSelectAPI_PHI();
            }
  
          }
          let doc
          if (assres.includes("withphi-data")){
            await extractpatientidjson(assres);
            doc = await getAllDocumentsAPI()
          }
          if(assres.includes("providing more details")){
            file_desc = demo
          }
          if (demo === "Cancel") {
            SetShowFileUpload(false);
          }
          if (assres.includes("secondOpinion-data-withoutPHI")) {
            SecondOpinionjson = extractsecondOpinionjson(assres);
          }
          if (assres.includes("secondOpinion-data-PHI")) {
            SecondOpinionjson1 = extractsecondOpinionjson(assres);
          }
          if(assres.includes("dictation-data") || assres.includes("video-data")){
            let patientID = await extractpatientidjson(assres);
          }
          if (assres.includes("pendingReferalOpinion-data")) {
            PendingSecondOpinionjson = extractpendingsecondOpinionjson(assres);
          }
          if (assres.toLowerCase().includes("dropdown:")) {
            let dropdownopt = extractDropdownopt(assres);
            SetDropdownNames(dropdownopt);
          }
          if (assres.includes("uploadVideo-data")) {
            UploadVideojson = extractuploadVideojson(assres);
          }

          if (assres.includes("Do you need any other help?")) {            
            setIsButtonDisabled((prevState) => !prevState);
            // setIsButtonDisabled(false)
            specialistName = "";  
            uploadVideoFileUrl =""
            SetPdfUrl([])          
            setClickedButton("");
            setmessagesa([]);
            SetbuttonNames([]);
            SetDropdownNames([]);
            SetShowFileUpload(false);
            setThreadButtonClicked(false);
            setContext(initialContext);           
            setIsGeneralSearch(false);
            Setisfaqbutton(false);
            Setisexpbutton(false);            
            SetisOtherQue(false);    
            SetisVideoQue(false) ;
            setSelectedFile(null);
            file = ""
      }
      // This function is for removing json from the Ui 
          SettextBeforeButtons(assres.split("Buttons:")[0]);
          let textBeforeBut = "";
          if (assres.toLowerCase().includes("dropdown:")) {
            textBeforeBut = assres.toLowerCase().split("dropdown:")[0];
          } else if (assres.includes("pendingReferalOpinion-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```") && test.split("}");;
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("secondOpinion-data-withoutPHI")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```") && test.split("}");;
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("secondOpinion-data-PHI")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```") && test.split("}");
            textBeforeBut = splitString[splitString.length - 1].trim();
          }else if (assres.includes("uploadVideo-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```") && test.split("}");;
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("userDoc-data")) {
            textBeforeBut = assres.split('"userDoc-data":')[0];
          } else if (assres.includes("Buttons:")) {
            textBeforeBut = assres.split("Buttons:")[0];
          } else {
            textBeforeBut = assres;
          }

          let userMessage;
          let newContext;
          if (assres.toLowerCase().includes("revenue code")){
            userMessage = { text: response, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres },{role:"system", content:`Here is your pdf link ${pdfData.data.pdf_url}`}];
          }
          else if (response !== "") {
            userMessage = { text: response, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres }];
          } else if(demo.includes("services_name")){
            userMessage = { text: demo, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres }, { role: "system", content: "Services provided by physician and their units as follows: \n"+ reportres }];
          }
          else if(demo === "Yes" || assres.includes("withphi-data")){
            userMessage = { text: demo, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres }, { role: "system", content: `patient documents are: ${JSON.stringify(doc)}` }];
          }
          else {
            userMessage = { text: demo, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres }];
          }

          if (assres.includes("withphi-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```") && test.split("}") ;           
            textBeforeBut = splitString[splitString.length - 1].trim();
          }
          let aiResponse = { text: textBeforeBut, user: false };
          if (demo.includes("New") || demo.includes("Exit")) {
            setmessagesa([userMessage, aiResponse]);
            // //speak({ text: aiResponse.text, voice: voices[4] });
          } else {
            setmessagesa([...messagesa, userMessage, aiResponse]);
            // //speak({ text: aiResponse.text, voice: voices[4] });
          }      
          if (assres.includes("Button")) {
            var buttonN = extractButtonNames(assres);
            SetbuttonNames(buttonN);
          } 
          setContext(newContext);         
          setResponse("");
          if (!threadApiCalled) {
            const obj = { question: demo, email: loggedInUserEmail };
            const add_thread = await axios.post(
              `${ACS_API}send_thread`,              
              obj
            );          
            thread_id = add_thread.data.new_row.Id;
            setThreadApiCalled(true);
            const response = await axios.get(
              `${ACS_API}get_all_threads/${loggedInUserEmail}`
            );
            categorizeThreads(response.data);
          }  
          if (!(demo === "Start New Conversation")) {
            send_msg_func(demo, textBeforeBut, thread_id);         
          }
        }
      } catch (error) {
        console.error("Error fetching AI response", error);
      }
    
  };

  useEffect(() => {
    if (messagesa.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesa]);

  const questionCount = async () => {
    let bodyc = { user_email: loggedInUserEmail };
    const resdata = await axios.post(
      `${OPENAI_API_NEW}ask`,
      // "http://127.0.0.1:5000/ask",
      bodyc
    );
    qcount = resdata.data.count;
    setCount(qcount);
  };

  const handleFileSelectAPI = async () => {
    try {
      let obj = new FormData();
      obj.append("loggedInUserId", SecondOpinionjsonObject.loggedInUserId);
      obj.append("companySlug", SecondOpinionjsonObject.companySlug);
      obj.append("physicianType", "1");
      obj.append("physicianId", SecondOpinionjsonObject.selectedPhysicianId);
      obj.append("specialtyId", SecondOpinionjsonObject.specialty);
      obj.append("subSpecialtyId", SecondOpinionjsonObject.subSpecialty);
      obj.append("emailTxt", SecondOpinionjsonObject.emailLogTxt);
      obj.append(
        "requestingPhyMobile",
        localStorage.getItem("loggedInUserMobile")
      );
      obj.append("requestingPhyEmail", loggedInUserEmail);
      obj.append("reuestTime", SecondOpinionjsonObject.reuestTime);
      obj.append("userOptionId", "2");
      obj.append("docType", "11");
      obj.append("assignedDelegateId", "undefined");
      obj.append("isDelegated", "0");
      obj.append("selectedDelegateId", SecondOpinionjsonObject.loggedInUserId);
      obj.append("fileKey1", selectedFile1);
      obj.append("fileKey2", selectedFile2);
      obj.append("fileKey3", selectedFile3);

      let apiurlphp = phpbaseurl + ApiHelper.php_docotr2doctorEmail02Api;
      let result = await axios.post(
        // "https://api.medrecordexchange.com/dev/docotr2doctorEmail_Chat.php",
        `${apiurlphp}`,
        obj
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileSelectAPI_PHI = async () => {
    try {
      let obj = new FormData();
      obj.append("loggedInUserId", SecondOpinionjsonObject.loggedInUserId);
      obj.append("companySlug", SecondOpinionjsonObject.companySlug);
      obj.append("physicianType", "1");
      obj.append("physicianId", SecondOpinionjsonObject.selectedPhysicianId);
      obj.append("specialtyId", SecondOpinionjsonObject.specialty);
      obj.append("subSpecialtyId", SecondOpinionjsonObject.subSpecialty);
      obj.append("fileDesTxt", SecondOpinionjsonObject.fileDesTxt);
      obj.append("docType", "11");
      obj.append("userOptionId", SecondOpinionjsonObject.userOptionId);
      obj.append("selectedDocsId", SecondOpinionjsonObject.selectedDocsId);
      obj.append("hippaFileDesTxt", null);
      obj.append("showNewFile","0");
      obj.append("attachmentDocObj",null);
      obj.append(
        "requestingPhyMobile",
        localStorage.getItem("loggedInUserMobile")
      );
      obj.append("requestingPhyEmail", loggedInUserEmail);
      obj.append("reuestTime", SecondOpinionjsonObject.reuestTime);
      obj.append("demographicUserId",SecondOpinionjsonObject.demographicUserId);
      obj.append("assignedDelegateId", "undefined");
      obj.append("isDelegated", "0");
      obj.append("selectedDelegateId", SecondOpinionjsonObject.loggedInUserId);
      let apiurlphp = phpbaseurl + ApiHelper.php_docotr2doctorEmail01Api
      let result = await axios.post(
        // "https://api.medrecordexchange.com/dev/docotr2doctorEmail_Chat.php",
        `${apiurlphp}`,
        obj
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    file = event.target.files && event.target.files[0];
    if (file) {
      fileNames = file.name;
      setFilename1(fileNames);
      if(demo === "Yes upload video" ){
        demo = `file_name ${fileNames}`
      }else if(demo==="Yes, claim"){
        demo = `services_name ${fileNames}`
      }else  if(demo === "Upload Existing Image"){
        demo = `image_name ${fileNames}`
      }
      let nContext=[
        ...context,
        {role:"system", content:`You have selected file name ${fileNames}`} ,
        {role:"user", content: demo} 
      ]
      setContext(nContext);
      setSelectedFile(file);
      const userMessage = { text: demo, user: true };
      let aiResponse = { text: "Please wait...", user: false };
      setmessagesa((prevmessagesa) => [
        ...prevmessagesa,
        userMessage,
        aiResponse,
      ]);
      SetShowFileUpload(false);
      SetbuttonNames([])
      if (filename1 === undefined || filename1 === null) {
        setFilename1(fileName);
        setSelectedFile1(file);        
      } else if (filename2 === undefined || filename2 === null) {
        setFilename2(file.name);
        setSelectedFile2(file);       
      } else if (filename3 === undefined || filename3 === null) {
        setFilename3(file.name);
        setSelectedFile3(file);        
      }
      getCompletionFrommessagesa(nContext)
    }
  };

  const initialquestionCount = async () => {
    let bodyc = { user_email: loggedInUserEmail };
    const resdata = await axios.post(
      `${OPENAI_API_NEW}get_initial_count_limit`,      
      bodyc
    );
    qcount = resdata.data.question_count;
    limit = resdata.data.limit;    
    setCount(qcount);
  };  
  const handleSendMessage = async () => {
    
    if (response.trim() !== "" || popres !== "" ) {      
      let prompt = response + popres;      
      demo = prompt;
      setResponse("");
      if (!isotherQue && !isvideoQue) {
        SetbuttonNames([]);
      }
      SetDropdownNames([]);
      let nContext: { role: string; content: any }[] = [];
      if (!isfaqbutton && prompt.toLowerCase().includes("second opinion")) {
        setClickedButton("second opinion");
        demo = "second opinion"
        if (!isexpbutton) {
          Setisexpbutton(true);
          nContext = [
            { role: "system", content: prompts.SecondOpinionCOntext(SpecialistList, patientDetails, filename1, filename2,filename3,file, selectedFile, loggedInUserId, ConstantHelper.COMPANY_SLUG) },
            { role: "user", content: prompt },
          ];
        } else {
          nContext = [...context, { role: "user", content: prompt }];
        }
        setIsGeneralSearch(false);
        Setisfaqbutton(false);
        Setisvdobutton(false);
        // Setisexpbutton(true)
      } else if (!isfaqbutton && prompt.toLowerCase().includes("task")) {
        setClickedButton("view pending tasks");
        setIsButtonDisabled(false);
        nContext = [
          { role: "system", content: prompts.pendingTaskContext(baseURL,loggedInUserId,doctorToDoctorEmailPendingCount,patientSecondOpinionPendingCount,physicianSecondOpinionPendingCount,expertSecondOpinionPendingCount) },
          { role: "user", content: prompt },
        ];
        Setisexpbutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisvdobutton(false);
      } else if (prompt.toLowerCase().includes("faq")) {
        setClickedButton("faq");
        nContext = [
          { role: "system", content: prompts.FAQ_Document(currentLinkGpt,documentURLRes,pageNumber) },
          { role: "user", content: prompt },
        ];
        Setisfaqbutton(true);
        Setisexpbutton(false);
        Setisvdobutton(false);
      } else if ( !isfaqbutton &&
        (/\b(?:patient.*details|details.*patient|active.*patient)\b/i.test(prompt.toLowerCase()))
      ) {        
        setClickedButton("patient details");
        nContext = [
          { role: "system", content: prompts.Patient_details(patientDetails, baseURL, loggedInUserId) },
          { role: "user", content: prompt },
        ];
        Setisexpbutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisvdobutton(false);
      }else if (
        prompt.toLowerCase().includes("upload video") 
      ) {
        setClickedButton("upload video");
        nContext = [
          { role: "system", content: prompts.uploadVideoContext(patientDetails, filename1, selectedFile) },
          { role: "user", content: prompt },
        ];
        Setisexpbutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisvdobutton(true);
      } else if (
        prompt.toLowerCase().includes("start dictation") 
      ) {        
        setClickedButton("start dictation");
        let data_test = await getPatientDetailsById();        
        nContext = [
          { role: "system", content: `Here are the patient Details: ${JSON.stringify(data_test)}, ${prompts.dictationContext_new}`},
          { role: "user", content: prompt },
        ];
        //startRecording();
        Setisexpbutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisvdobutton(true);
      }else if (prompt.toLowerCase().includes("Top 5 Documents") ||
      prompt.toLowerCase().includes("see my documents") ||
      prompt.toLowerCase().includes("documents")){             
          SetbuttonNames(['Compare', 'Summarize', 'Anatomical Abnormalities', 'Other Question', 'Reset'])
          let aiResponse = { text: html_table, user: false };
          let userMessage = { text: demo, user: true };
          setmessagesa([...messagesa, userMessage, aiResponse]);
      }
      else if ( !isfaqbutton &&(
        prompt.toLowerCase().includes("pending opinion") ||
        prompt.toLowerCase().includes("pending opinions") ||
        prompt.toLocaleLowerCase().includes("Review list of pending opinions"))
      ) {
        setClickedButton("respond expert 2nd opinion");
        nContext = [
          { role: "system", content: prompts.pendingReferalOpinion(pendingReferalOpinionList, formattedPhoneNumber, updatedLoggedInUserName) },
          { role: "user", content: prompt },
        ];
        setIsGeneralSearch(false);
        Setisexpbutton(false);
      } else if (
        prompt.toLowerCase().includes("exit chat") ||
        prompt.toLowerCase().includes("thanks")
      ) {
        nContext = [
          { role: "system", content: prompts.newChat(loggedInUserName) },
          { role: "user", content: "New Chat" },
        ];
        Setisfaqbutton(false);
        SetDropdownNames([]);
        setClickedButton("");
        setIsGeneralSearch(false);
        Setisexpbutton(false)
      } else if (prompt.toLowerCase().includes("stop dictation")){
        stopRecording()
        nContext = [
          ...context,         
          { role: "user", content: "Stop Dictation" },
        ]; 
        SetbuttonNames([])
      } else if (prompt.toLowerCase().includes("pause dictation")){
        setClickedButton("pause dictation");
        pauseRecording()         
        SetisPausebutton(false);
      }else if (prompt.toLowerCase().includes("resume dictation")){
        setClickedButton("resume dictation");
        resumeRecording() 
        SetisResumebutton(false);      
      }

      else {
        nContext = [...context, { role: "user", content: prompt }];
      }

      if(
        !prompt.toLowerCase().includes("Top 5 Documents") &&
        !prompt.toLowerCase().includes("see my documents") &&
        !prompt.toLowerCase().includes("my documents")
        ){
      setContext(nContext);
      const userMessage = { text: prompt, user: true };
      let aiResponse = { text: "Please wait...", user: false };
      setmessagesa((prevmessagesa) => [
        ...prevmessagesa,
        userMessage,
        aiResponse,
      ]);
      
      if (
        isGeneralSearch &&
        !prompt.includes("Hi") &&
        !prompt.includes("exit chat") &&
        prompt.includes("?")
      ) {
        SetbuttonNames([]);
        try {          
          if (prompt.toLowerCase().includes("patient list")) {
            prompt = `Give the me the list of patients full name who are registered under physician id ${loggedInUserId} from user_doctors and user_login table.?`;
          }
          const body = {
            prompt: prompt,
            loggedInUserId: loggedInUserId,
            baseURL: baseURL,
            user_id: loggedInUserId
          };
          const res = await axios.post(
            `${OPENAI_API_NEW}gen_search`,            
            body
          );
          let ans = res.data.answer;
          setmessagesa((prevmessagesa) => {
            const updatedmessagesa = prevmessagesa.slice(0, -1);
            return [...updatedmessagesa, { text: ans, user: false }];
          });
          //speak({ text: ans , voice: voices[4]});
          questionCount();
        } catch (err) {
          console.error("Error:", err);
        }
      } else if (isfaqbutton && !prompt.includes("thanks")) {
        try {
          const body = {
            question: response,
            loggedInUserRoleId: loggedInUserRoleId
          };
          try {
            const res = await axios.post(
              `${OPENAI_API_NEW}faq_search`,              
              body
            );
            let ans = res.data.answer;
            aiResponse = { text: ans, user: false };
            setmessagesa((prevmessagesa) => {
              const updatedmessagesa = prevmessagesa.slice(0, -1);
              return [...updatedmessagesa, aiResponse];
            });
            //speak({ text: aiResponse.text , voice: voices[4]});
            send_msg_func(response, ans, thread_id);
            questionCount();
          } catch (error) {
            if (error.response && error.response.status === 500) {
              setmessagesa((prevmessagesa) => {
                aiResponse = {
                  text: "Data not available in FAQ, please ask another question",
                  user: false,
                };
                const updatedmessagesa = prevmessagesa.slice(0, -1);
                return [...updatedmessagesa, aiResponse];
              });              
            }
          }
        } catch (err) {
          console.error("Error:", err);
        }
      } else if (isotherQue && !prompt.includes("thanks")) {        
        let r1;
        if (pdfUrl.length > 0) {
          r1 = await report(prompt, pdfUrl);
        }
        if (imageUrl.length > 0) {
          r1 = await report(prompt, imageUrl);
        }
       
        aiResponse = { text: r1, user: false };
        let userMessage = { text: prompt, user: true };
        setmessagesa([...messagesa, userMessage, aiResponse]);
        //speak({ text: aiResponse.text,voice: voices[4] });
        send_msg_func(demo, r1, thread_id);
        questionCount();
      } else if (isvideoQue && !prompt.includes("thanks")) {
        let r1;
        if(videoUrl.length !== 0){
           r1 = await report(prompt, videoUrl);
        }else{
          r1 = await generateVideoURI(prompt);
        }       
        aiResponse = { text: r1, user: false };
        let userMessage = { text: prompt, user: true };
        setmessagesa([...messagesa, userMessage, aiResponse]);
        //speak({ text: aiResponse.text,voice: voices[4] });
        send_msg_func(demo, r1, thread_id);
        questionCount();
      }else {
        try {
          getCompletionFrommessagesa(nContext); // Wait for completion
        } catch (error) {
          console.error("Error sending message", error);
        }
      }
       }
    }
  };


  function handleExitChat(event) {
    // event.preventDefault();
    if (!isButtonDisabled) {
      SweetAlertHelper.showConfirm(
        "Would you like to exit from the current chat context and initiate a new conversation?",
        () => {
          setIsButtonDisabled((prevState) => !prevState);
          specialistName = "";
          uploadVideoFileUrl = "";
          prevVideoSummary = "";
          SetPdfUrl([]);
          setClickedButton("");
          setmessagesa([]);
          SetbuttonNames([]);
          SetDropdownNames([]);
          setThreadButtonClicked(false);
          setIsGeneralSearch(false);
          Setisfaqbutton(false);
          Setisexpbutton(false);
          SetShowFileUpload(false);
          SetisOtherQue(false);    
          SetisVideoQue(false) ;   
          setSelectedFile(null)
          demo = "Start New Conversation";
          let nContext = [
            { role: "system", content: prompts.newChat(loggedInUserName) },
            { role: "user", content: "New Chat" },
          ];
          // setContext(nContext);
          setContext(initialContext);
          setThreadApiCalled(false);
          // Create messages for user action and AI response
          const userMessage = {
            text: "Start New Conversation",
            user: true,
          };
          let aiResponse = {
            text: "please wait...",
            user: false,
          };
          setmessagesa([userMessage, aiResponse]);
          //speak({ text: aiResponse.text, voice: voices[4] });
          getCompletionFrommessagesa(nContext); // Reset clicked button state
        }
      );
    }
  }

  useEffect(() => {
   
  }, [setClickedButton]);

  async function handleStaticButton(buttonName) {
    setActiveClicked(true)
    setClickedButton(buttonName);
    setIsButtonDisabled(false);
    let nContext;
    demo = buttonName;
    if (buttonName.toLowerCase() === "second opinion") {
      nContext = [
        { role: "system", content: prompts.SecondOpinionCOntext(SpecialistList, patientDetails, filename1, filename2,filename3,file, selectedFile, loggedInUserId, ConstantHelper.COMPANY_SLUG) },
        { role: "user", content: "Second opinion" },
      ];
      Setisexpbutton(true);
      Setisfaqbutton(false);
    }
    else if (buttonName.toLowerCase() === "start dictation") {
      nContext = [
        { role: "system", content: prompts.dictationContext(patientDetails) },
        { role: "user", content: "Start Dictation" },
      ];
      Setisfaqbutton(false);
    }
    else if (buttonName.toLowerCase() === "stop dictation") {
      nContext = [
        { role: "system", content: prompts.dictationContext(patientDetails) },
        { role: "user", content: "Stop Dictation" },
      ];
      Setisfaqbutton(false);
    }
    
    else if (buttonName.toLowerCase() === "Yes, Start Dictation") {
      nContext = [
        { role: "system", content: prompts.dictationContext(patientDetails) },
        { role: "user", content: "Yes, Start Dictation" },  
      ];      
      startRecording(); // Function to handle the stop of recording
    }

    else if (buttonName.toLowerCase() === "respond expert 2nd opinion") {
      nContext = [
        { role: "system", content: prompts.pendingReferalOpinion(pendingReferalOpinionList, formattedPhoneNumber, updatedLoggedInUserName) },
        { role: "user", content: "Respond Expert 2nd Opinion" },
      ];
      Setisfaqbutton(false);
      Setisexpbutton(false);
    } else if (buttonName.toLowerCase() === "faq") {
      nContext = [
        { role: "system", content: prompts.FAQ_Document(currentLinkGpt,documentURLRes,pageNumber) },
        { role: "user", content: "FAQ" },
      ];
      Setisfaqbutton(true);
      Setisexpbutton(false); // Assuming this is a function to set a state or context for FAQ
    } else if (buttonName.toLowerCase() === "patient details" || buttonName.toLowerCase().includes("active patient")) {
      setClickedButton("patient details");
      nContext = [
        { role: "system", content: prompts.Patient_details(patientDetails, baseURL, loggedInUserId) },
        { role: "user", content: buttonName },
      ];
      Setisfaqbutton(false);
      Setisexpbutton(false);
    }
    else if (buttonName.toLowerCase() === "upload video") {
      setClickedButton("upload video");       
      setIsGeneralSearch(false);       
      nContext = [
        { role: "system", content: prompts.uploadVideoContext(patientDetails, filename1, selectedFile) },
        { role: "user", content: buttonName },
      ];
      Setisfaqbutton(false);
      Setisexpbutton(false);
      Setisvdobutton(true);      
    }  
    
    setContext(nContext);
    const userMessage = {
      text: buttonName,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);    
    getCompletionFrommessagesa(nContext);
    SetbuttonNames([]);
  }

  useEffect(() => {    
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, []);

  useEffect(() => {    
    if (messagesa.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesa]);


  return (
    <div>{isLoading && <div className="page-loader"><img src="../../assets/images/ajax-loader.gif" /></div>}
    <div className="chat-container" ref={chatContainerRef}>
      <div
        className="nav-bar"
        style={{ display: isSidebarOpen && isPopUp ? "block" : "none" }}
      >
        <div>
          <h5 style={{ marginLeft: "11px" }}>
            {" "}            
            <strong style={{ display: isSidebarOpen && isPopUp ? "block" : "none"}}>Chat History</strong>{" "}
          </h5>
        </div>

        <button
          // className="exist-button-history"
          className={`exist-button ${
            isButtonDisabled  && isPopUp ? "disabled-class" : "enabled-class"
          }`}
          // className="send-button"
          onClick={handleExitChat}
          disabled={count >= limit && isButtonDisabled}
          style={{
            marginLeft: "11px",
            marginRight: "11px",
            width: "296px",
            padding: "7px",
          }}
          title="Start new conversation"
        >
          Start new Conversation
        </button>

        <ul className="parent-nav-list">
          {todayThread.length > 0 && (
            <li>
              <strong style={{ color: "black" }}>Today</strong>
              {todayThread.map((thread, index) => (
                <ul className="nav-list" key={index}>
                  <button
                    className={`thread_btn ${
                      activeThread === index ? "active-button" : ""
                    }`}
                    onClick={() => loadThread(thread, index)}
                  >
                    {hasMessages(thread.name) && (
                      <FontAwesomeIcon
                        icon={faMessage}
                        className="message-icon"
                      />                      
                    )}

                    {thread.name}
                  </button>
                </ul>
              ))}
            </li>
          )}
          {last7DaysThreads.length > 0 && (
            <li>
              <strong style={{ color: "black" }}>Last 7 Days</strong>
              {last7DaysThreads.map((thread, index) => (
                <ul className="nav-list" key={index}>
                  <button
                    className="thread_btn"
                    onClick={() => loadThread(thread, index)}
                  >
                    {hasMessages(thread.name) && (
                      <FontAwesomeIcon
                        icon={faMessage}
                        className="message-icon"
                      />
                    )}
                    {thread.name}
                  </button>
                </ul>
              ))}
            </li>
          )}
          {olderThread.length > 0 && (
            <li>
              <strong style={{ color: "black" }}>Older Chats</strong>
              {olderThread.map((thread, index) => (
                <ul className="nav-list" key={index}>
                  <button
                    className="thread_btn"
                    onClick={() => loadThread(thread, index)}
                  >
                    {hasMessages(thread.name) && (
                      <FontAwesomeIcon
                        icon={faMessage}
                        className="message-icon"
                      />
                    )}
                    {thread.name}
                  </button>
                </ul>
              ))}
            </li>
          )}
        </ul>
      </div>
      <div className="chat-col lg-10" >
        <div className="count-container bold-text">
          {count} of {limit}
        </div>
        {count >= limit && isPopUp && (
          <div className="count-container bold-text" style={{ color: "red" }}>
            You have reached your daily question limit
          </div>
        )}

        <div className="message-container">
          {messagesa.map((message, index) => (
            <div
              key={index}
              style={{
                alignSelf: message.user ? "flex-end" : "flex-start",
                backgroundColor: message.user ? "#e1ffc7" : "#f0f0f0",
                borderRadius: "10px",
                padding: "8px 12px",
                marginBottom: "5px",
                maxWidth: "80%",
              }}
            >
              {message.user ? (
                message.text
              ) : (
                <>
                  {typeof message.text === "object" ? (
                    JSON.stringify(message.text)
                  ) : (
                    <>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <FontAwesomeIcon
                          icon={faClipboard}
                          onClick={() =>
                            handleCopyToClipboard(message.text, index)
                          }
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          color={copiedIndex === index ? "green" : "#1c84c6"}
                          title={
                            copiedIndex === index ? "Copied!" : "Copy Text"
                          }
                        />
                        <FontAwesomeIcon
                          icon={
                            speaking && !isSpeechPaused ? faPause : faVolumeUp
                          }
                          onClick={handleVolumeIconClick(message.text)}
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: "#1c84c6",
                          }}
                          title={
                            speaking && !isSpeechPaused ? "Pause" : "Listen"
                          }
                        />
                      </div>                   
                      {(message.text.includes("<table")) ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: message.text }}
                        />
                      ) : (
                        message.text.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            <div dangerouslySetInnerHTML={{ __html: line }} />
                          </React.Fragment>
                        ))
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ))}
          {dropdownNames.length > 0 && (
            <div>
              <select onChange={handleDropdownChange}>
                <option value="">Select an option</option>
                {dropdownNames.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          {showFileUpload && (
            <div>
              <input type="file" onChange={handleFileSelect} />
              {selectedFile && <p>Selected File: {selectedFile.name}</p>}
            </div>
          )}
           {isDialogOpen && (            
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: 20,
                  background: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                
                {!photoCaptured ? (
                  <>
                  
                    <video ref={videoRef} style={{ width: "500px" }} autoPlay></video>
                    <button onClick={captureImage} style={{ marginTop: "10px" }}>
                      Capture Image
                    </button>
                  </>
                ) : (
                  <>
                    <img
                      src={image}
                      alt="Captured"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <button onClick={retakePhoto} style={{ marginRight: "10px" }}>
                        Retake Photo
                      </button>
                      <button onClick={uploadImage}>Upload Image</button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {(textBeforeButtons !== ""|| html_table !== "") && (
            <div>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {buttonNames.map((buttonName, index) => (
                   <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <button
                    className="send-button"
                    key={index}
                    onClick={async () => {
                      demo = buttonName;
                      // setResponse(buttonName);
                      let nContext;
                      if(buttonName === "Capture New Image"){
                        setIsDialogOpen(true)
                        startVideo();                        
                      }
                      if (buttonName === "Second opinion") {
                        nContext = [
                          { role: "system", content: prompts.SecondOpinionCOntext(SpecialistList, patientDetails, filename1, filename2,filename3,file, selectedFile, loggedInUserId, ConstantHelper.COMPANY_SLUG) },
                          { role: "user", content: buttonName },
                        ];
                      }                      
                      else if (buttonName === "Respond Expert 2nd Opinion") {
                        nContext = [
                          { role: "system", content: prompts.pendingReferalOpinion(pendingReferalOpinionList, formattedPhoneNumber, updatedLoggedInUserName) },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName === "FAQ") {
                        nContext = [
                          { role: "system", content: prompts.FAQ_Document(currentLinkGpt,documentURLRes,pageNumber) },
                          { role: "user", content: buttonName },
                        ];
                        Setisfaqbutton(true);
                      } else if (buttonName === "Patient details") {
                        nContext = [
                          { role: "system", content: prompts.Patient_details(patientDetails, baseURL, loggedInUserId) },
                          { role: "user", content: buttonName },
                        ];
                      }else if (buttonName === "Upload Video") {
                        nContext = [
                          { role: "system", content: prompts.uploadVideoContext(patientDetails, filename1, selectedFile) },
                          { role: "user", content: buttonName },
                        ];
                      }                     
                      else if (buttonName.includes("New")) {
                        nContext = [
                          { role: "system", content: prompts.newChat(loggedInUserName) },
                          { role: "user", content: buttonName },
                        ];
                      } else if (buttonName.includes("Compare")) {
                        setIsLoading(true)
                        let aiResponse = {
                          text: "Please wait, I am processing reports...",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);                        
                        let r1;                       
                        if (pdfUrl.length === 2) {
                          r1 = await report("Compare the reports", pdfUrl);
                        }else{
                          r1 = "Please select two pdf reports to compare. \n"
                        }
                        if (imageUrl.length > 0) {                          
                          r1 = "Please select two pdf reports to compare, I am unable to compare images. \n"
                        }
                        if (videoUrl.length > 0) {                          
                          r1 = "Please select two pdf reports to compare, I am unable to compare videos. \n"
                        }                        
                        let aire =
                          r1 +
                          "\n\nIf you have any question on this report please enter the question or else click on Reset button to choose other reports.";
                        aiResponse = {
                          text: aire,
                          user: false,
                        };
                        userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);                        
                        setIsLoading(false)
                        SetisOtherQue(true);
                        SetisVideoQue(false);
                        send_msg_func(demo, aire, thread_id);
                        questionCount();
                      }
                      else if(buttonName === "Detailed Summary"){
                        setIsLoading(true)
                        let aiResponse = {
                          text: "Please wait, I am processing video...",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        //speak({ text: aiResponse.text ,voice: voices[4]});  
                      //  videosum = await generateVideoURI("if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points"); 
                      videosum = await generateVideoURI( `Following is the video of ${file_desc} If it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a diagnosis if required. Display the information as HTML paragraph only using <b>, <h6>, and <ul> for bullet points.`);
                      prevVideoSummary=videosum;
                       videosum += "\n\nIf you have any other question on this video, please enter in below box.";                       
                        aiResponse = {
                        text: videosum,
                        user: false,
                      };
                        userMessage = { text: demo, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false)                        
                        send_msg_func(demo, videosum, thread_id);
                        questionCount();                               
                        SetisVideoQue(true)    
                        SetisOtherQue(false)                          
                        SetShowFileUpload(false);
                        setThreadButtonClicked(false);
                      } 
                      else if(buttonName === "Anatomical Abnormalities"){
                        setIsLoading(true)
                        let aiResponse = {
                          text: "Please wait, I am processing video...",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);                        
                       videosum = await generateVideoURI("What anatomical abnormalities are evident in this visual examination?"); 
                       prevVideoSummary=videosum;
                       videosum += "\n\nIf you have any other question, please enter in below box.";                       
                        aiResponse = {
                        text: videosum,
                        user: false,
                      };
                        userMessage = { text: demo, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false)                       
                        send_msg_func(demo, videosum, thread_id);
                        questionCount();              
                        SetisVideoQue(true)  
                        SetisOtherQue(false)                                                           
                        SetShowFileUpload(false);
                        setThreadButtonClicked(false);
                      } 
                      else if (buttonName.includes("Ask Question")) {
                        let aiResponse = {
                          text: "Please enter your Question in the box below",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);                       
                        SetisVideoQue(true);
                        SetisOtherQue(false)                          
                        questionCount();
                      }
                      else if (buttonName.includes("Summarize")) {
                        setIsLoading(true)
                        let aiResponse = {
                          text: "Please wait, I am processing reports...",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);                        
                        let r1;
                        if (pdfUrl.length > 0) {
                          if (pdfUrl.length !== 1){
                            r1 = "Please select only one document to summarize!"
                          }else{
                          r1 = await report("Summarize the report", pdfUrl);
                          SetisOtherQue(true);
                          }
                        }
                        if (imageUrl.length > 0) {
                          if (imageUrl.length !== 1){
                            r1 = "Please select only one document to summarize!"
                          }else{
                            r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", imageUrl);
                            SetisOtherQue(true);
                          }
                        }
                        if(videoUrl.length > 0){
                          r1 = await report("if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", videoUrl);
                          SetisVideoQue(true);
                        }
                        let aire =
                          r1 +
                          "\n \nIf you have any question on this report please enter the question or else click on Reset button to choose other reports.";
                        aiResponse = { text: aire, user: false };
                        userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false)   
                        
                        send_msg_func(demo, aire, thread_id);
                        questionCount();
                      } 
                      else if (buttonName.includes("Show Summary")){                        
                        setIsLoading(true)
                        let aiResponse;
                        if (buttonName.includes("Show Summary")){
                          aiResponse = {
                            text: "Please wait, I am generating summary of dictation...",
                            user: false,
                        };
                        }
                        
                        let userMessage = { text: buttonName, user: true };
                        SetbuttonNames([]);
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        //speak({ text: aiResponse.text ,voice: voices[4]});  
                        let videosum = await returnSummary(audioBlob); 
                        aiResponse = {
                        text: videosum || "Unable to generate summary for audio...Please try again.",
                        user: false,
                        };                        
                        userMessage = { text: demo, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false)
                        //speak({ text: aiResponse.text ,voice: voices[4]});  
                        send_msg_func(demo, videosum, thread_id);
                        questionCount();                        
                        setIsButtonDisabled((prevState) => !prevState);
                        setClickedButton("");                        
                        SetShowFileUpload(false);
                        setThreadButtonClicked(false);
                        setContext(initialContext);
                        // setThreadApiCalled(false);
                      }else if (buttonName.includes("Other Question")) {
                        let aiResponse = {
                          text: "Please type your Other Question in the box below",
                          user: false,
                        };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        //speak({ text: aiResponse.text, voice: voices[4] });
                        SetisOtherQue(true);
                        SetisVideoQue(false);
                        questionCount();
                      } else if (buttonName.includes("Reset")) {
                        let aiResponse = { text: checkboxtable, user: false };
                        let userMessage = { text: buttonName, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        //speak({ text: aiResponse.text, voice: voices[4] });
                        send_msg_func(demo, checkboxtable, thread_id);
                        SetPdfUrl([]);
                        SetImageUrl([]);
                        setVideoUrl([]);
                      } else if (buttonName === "Yes, Start Dictation"){                    
                        nContext = [
                          ...context,
                          { role: "user", content: buttonName }                         
                        ];
                        startRecording(); 
                      }else if (buttonName === "Stop Dictation"){                           
                        nContext = [
                          ...context,
                          {role: "system", content: `${summaryValue}`},
                          { role: "user", content: buttonName },
                        ];
                        await stopRecording();
                        // setIsLoading(true)
                      } else if (buttonName === "Pause Dictation"){                                                    
                        
                        // pauseRecording(); 
                        setClickedButton("Pause Dictation");
                        pauseRecording();
                        SetisPausebutton(true);
                        SetisResumebutton(false);
                      }else if (buttonName === "Resume Dictation"){                                                        
                        // resumeRecording();
                        setClickedButton("Resume Dictation");
                        resumeRecording();
                        SetisPausebutton(false);
                        SetisResumebutton(true);
                      }else if(buttonName === "Capture New Image"){
                        setIsDialogOpen(true)
                      }
                      else {
                        nContext = [
                          ...context,
                          { role: "user", content: buttonName },
                        ];
                      }
                      if (
                        buttonName !== "Compare" &&
                        buttonName !== "Summarize" &&
                        buttonName !== "Other Question" &&
                        buttonName !== "Reset" &&
                        buttonName !== "Detailed Summary" &&
                        buttonName !== "Anatomical Abnormalities" &&
                        buttonName !== "Show Summary" &&
                        buttonName !== "Ask Question" &&
                        buttonName !== "Capture New Image" &&
                        buttonName !== "Pause Dictation" &&
                        buttonName !== "Resume Dictation"
                        // buttonName !== "Show Prescription"
                      ) {
                        setContext(nContext);
                        const userMessage = {
                          text: buttonName,
                          user: true,
                        };
                        let aiResponse = {
                          text: "please wait...",
                          user: false,
                        };
                        if (buttonName.includes("New")) {
                          setmessagesa([userMessage, aiResponse]);
                          //speak({ text: aiResponse.text,voice: voices[4] });
                        } else {
                          setmessagesa([...messagesa, userMessage, aiResponse]);
                          //speak({ text: aiResponse.text, voice: voices[4] });
                        }
                        getCompletionFrommessagesa(nContext);
                        SetbuttonNames([]);
                      }
                    }}
                    disabled={
                      (buttonName === "Pause Dictation" && isPausebutton) ||
                      (buttonName === "Resume Dictation" && isResumebutton)
                    }
                  >
                    {buttonName}
                    {/* {buttonName === "Stop Dictation" && isRecording && ` (Time Left: ${formatTimeLeft()})`} */}
                  </button>
                  {buttonName === ("Stop Dictation" || "Pause Dictation" || "Resume Dictation")  && (
                    <span className="digital-clock"> {`Time Elapsed: ${formatTimeLeft()}`}</span>
                  )}
                  </div>
                ))}
              </div>
              
            </div>
          )}
          <div ref={lastMessageRef}></div>

          <button
            onClick={toggleSidebar}
            className="toggle-button"
            title={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
          >
            <FontAwesomeIcon
              className="icon"
              icon={isSidebarOpen && isPopUp ? faChevronLeft : faChevronRight}
            />
          </button>
        </div>
        {/*  canvas */}
        <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        width="400"
        height="400"
      ></canvas>
      {/*  */}
      <div className="test" style={{ display: isPopUp ? "block" : "none" }}>
      {isPopUp === 'false' && (
        <div
          className="buttoArray"
          style={{ display: `${threadButtonClicked? "none" : ""}` }}
        >
          <button
            className= {`send-button ${activeclicked && clickedButton === "second opinion" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("second opinion")}            
            disabled={
              (count >= limit || (clickedButton !== "" && clickedButton !== "second opinion")) ||
              (activeclicked && clickedButton === "second opinion")
            }
            title=" Ask Second opinion"
          >
            
            Ask Second opinion
          </button>      

          <button
            className= {`send-button ${activeclicked && clickedButton === "respond expert 2nd opinion" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("respond expert 2nd opinion")}
            disabled={
              (count >= limit ||
              clickedButton !== "" && clickedButton !== "respond expert 2nd opinion") ||
              (activeclicked && clickedButton === "respond expert 2nd opinion")
            }
            title="Respond Expert 2nd Opinion"
          >
            Respond Expert 2nd Opinion
          </button>

          <button
            className= {`send-button ${activeclicked && clickedButton === "faq" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("faq")}
            disabled={
              (count >= limit || (clickedButton !== "" && clickedButton !== "faq")) ||
              (activeclicked && clickedButton === "faq")
            }
            title="FAQ"
          >
            FAQ
          </button>

          <button
            className= {`send-button ${activeclicked && clickedButton === "patient details" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("patient details")}            
            disabled={
              (count >= limit || (clickedButton !== "" && clickedButton !== "patient details")) ||
              (activeclicked && clickedButton === "patient details")
            }
            title="Patient details"
          >
            Patient details
          </button>
          <button
            className= {`send-button ${activeclicked && clickedButton === "upload video" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("upload video")}
            disabled={
              (count >= limit || (clickedButton !== "" && clickedButton !== "upload video")) ||
              (activeclicked && clickedButton === "upload video")
            }
            title="Upload Video"
          >            
            Upload Video
          </button>
          <button
            className= {`send-button ${activeclicked && clickedButton === "start dictation" ? "green-class" : '' }`}
            onClick={() => handleStaticButton("start dictation")}
            disabled={
              (count >= limit || (clickedButton !== "" && clickedButton !== "start dictation")) ||
              (activeclicked && clickedButton === "start dictation")
            }
            title="Start Dictation"
          >
            Start Dictation
          </button>
          {
          
          }
          <button
            className={`exist-button ${
              isButtonDisabled ? "disabled-class" : "enabled-class"
            }`}
            // className="send-button"
            onClick={handleExitChat}
            disabled={count >= limit && isButtonDisabled}
            title="Start new Conversation"
          >
            Start new Conversation
          </button>      

        </div>)}
        </div>
        <div>
        {isPopUp === 'false' && (
        <div className="chat-input-container">
          <div className="input-with-mic">
            <input
              type="text"
              placeholder="Type your message..."
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              className="chat-input"
              disabled={count >= limit || threadButtonClicked}
            />
             <button
              onClick={startSpeechRecognition}
              className="mic-button"
              title="Mic"
            >
              {isMicrophoneOn ? (
                <FontAwesomeIcon
                  icon={faMicrophone}
                  style={{ fontSize: "1.5em", color: "#1c84c6" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMicrophoneSlash}
                  style={{ fontSize: "1.5em", color: "#1c84c6" }}
                />
              )}

            </button>
          </div>
          <button
            onClick={handleSendMessage}
            className="send-button"
            disabled={count >= limit || threadButtonClicked}
            title="Send"
          >
            Send
          </button>
          <button
            type="submit"
            onClick={resetAllData}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "blue",
              display: "flex",
              alignItems: "center",
            }}
            disabled={count >= limit}
            title="Reset"
          >
            <FiRefreshCw
              size={20}
              style={{ marginRight: "5px", color: "#1c84c6" }}
            />
          </button>
        </div>)}
        </div>

        <div className="ssttag">
          <img
            src="../../assets/images/SST Logo1.jpg"
            alt="SSTAI"
            className="logo"
                        
          ></img>
          <span style={{ opacity: 1 }}>SSTAI IP</span>          
        </div>        
      </div>
    </div>
    </div>
  );
};

export default AHSChatBot;
